import React from 'react';
import { Location } from '@reach/router';

import EventCalendarDetail from '../containers/event-calendar/event-calendar-detail';
import EventCalendarList from '../containers/event-calendar/event-calendar-list';

export default function EventCalendarPage() {
  return (
    <Location>
      {({ location }) => {
        return (
          <>
            <EventCalendarList location={location} />
            <EventCalendarDetail location={location} />
          </>
        );
      }}
    </Location>
  );
}
